<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in footerComponents"
    :key="`footerComponents-${index}`"
    :component="component"
    :component-data="component"
  ></component>
</template>

<script lang="ts">
import { FooterMixin } from '~/mixins/footer.mixin';
import FooterOne from '~/components/theme3/base/footer/FooterOne.vue';

export default defineNuxtComponent({
  name: 'Theme3TheFooter',
  components: { FooterOne },

  mixins: [FooterMixin],
});
</script>
